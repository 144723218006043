<template>
  <Breadcrumb :items="breadcrumbs" />
  <h1 class="title title--main title--page mb-3 text-center">{{ t('forgot_password') }}</h1>
  <form class="form-in" @submit.prevent="reset" @keydown="form.onKeydown($event)">
    <div v-if="status" class="alert alert-success">{{ status }}</div>
    <img src="@/assets/img/form-bg.png" class="form-in__bg">
    <div class="form-group">
      <div class="form-label">{{ t('email') }}</div>
      <input v-model="form.email" type="email" name="email" class="form-control" :class="{ 'is-invalid': form.errors.has('email') }" >
      <div v-if="form.errors.has('email')" v-html="form.errors.get('email')" class="invalid-feedback" />
    </div>
    <div class="form-group">
      <div class="form-label">{{ t('password') }}</div>
      <input v-model="form.password" type="password" name="email" class="form-control" :class="{ 'is-invalid': form.errors.has('password') }" >
      <div v-if="form.errors.has('password')" v-html="form.errors.get('password')" class="invalid-feedback" />
    </div>
    <div class="form-group">
      <div class="form-label">{{ t('password_confirmation') }}</div>
      <input v-model="form.password_confirmation" type="password" name="email" class="form-control" :class="{ 'is-invalid': form.errors.has('password_confirmation') }" >
      <div v-if="form.errors.has('password_confirmation')" v-html="form.errors.get('password_confirmation')" class="invalid-feedback" />
    </div>
    <br>
    <div class="text-center">
      <button class="btn btn--blue" type="submit">{{ t('reset_password') }}</button>
    </div>
  </form>
</template>
<script>
import { useI18n } from 'vue-i18n'
import { useMeta } from 'vue-meta'
import { ref } from '@vue/runtime-core'
import { useRoute } from 'vue-router'
import Form from 'vform'
export default {
  setup () {
    const { t } = useI18n()
    useMeta({
      title: t('password_reset')
    })
    const route = useRoute()
    const form = ref(new Form({
      token: route.params.token,
      email: route.query.email,
      password: '',
      password_confirmation: ''
    }))
    const status = ref('')
    async function reset () {
      const { data } = await form.value.post(process.env.VUE_APP_API_URL + '/password/reset')
      status.value = data.status
      form.value.reset()
    }
    return {
      t,
      status,
      form,
      reset,
      breadcrumbs: [
        { name: t('home'), href: '/' },
        { name: t('password_reset'), href: null, active: true }
      ]
    }
  }
}
</script>
